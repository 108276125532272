<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  Button,
  Card,
  DateTimeFormat,
  DetailList,
  DForm,
  DGroup,
  Empty,
  Loading,
  MenuItem,
} from '@/components'
import { getApi, useApi } from '@/plugins/api'
import { jobFormSchema } from '@/forms'
import { useI18n } from 'vue-i18n'
import { Candidate, Job, Rubric, ScrollResponse } from '@@/types'
import {
  PhBuildingOffice,
  PhCheck,
  PhDotsThreeOutline,
  PhDownloadSimple,
  PhPencilSimpleLine,
  PhCirclesThreePlus,
  PhMoneyWavy,
  PhTrash,
  PhTrayArrowDown,
} from '@phosphor-icons/vue'

const route = useRoute()
const router = useRouter()

const { t } = useI18n()

const api = getApi()

const id = route.params.id
const { data: job, execute, isLoading } = useApi<Job>(`/jobs/${id}`)

const candidates = ref<Candidate[]>([])
const totalCandidates = ref(0)
let scrollId: string | null = null

const isLoadingCandidates = ref(false)

const fetchCandidates = async () => {
  isLoadingCandidates.value = true

  const params = {
    page_size: 5,
    ...(scrollId ? { scroll_id: scrollId } : {}),
  }

  const res = await api.get<ScrollResponse<Candidate>>(
    `/jobs/${id}/candidates`,
    { params }
  )
  isLoadingCandidates.value = false

  candidates.value = candidates.value.concat(res.data.results)
  totalCandidates.value = res.data.totalCount
  scrollId = res.data.scrollId
}
fetchCandidates()

const fetchRubrics = async () => {
  const res = await api.get<Rubric[]>('/rubrics')

  jobFormSchema.fields[
    jobFormSchema.fields.findIndex((f) => f.name === 'rubricId')
  ].options = res.data.map((r) => ({ value: r.id, label: r.name }))
}
fetchRubrics()

const save = async (data: Partial<Job>) => {
  const res = await api.put<Job>(`/jobs/${id}`, {
    description: data.description,
    rubricId: data.rubricId,
    employeeResume1: data.employeeResume1 ?? '',
    employeeResume2: data.employeeResume2 ?? '',
    employeeResume3: data.employeeResume3 ?? '',
    employeeResume4: data.employeeResume4 ?? '',
    employeeResume5: data.employeeResume5 ?? '',
  })
  console.log('ding', res)
  await execute()
}

const deleteJob = async () => {
  if (!confirm(t('messages.confirm'))) return
  const res = await api.delete(`/jobs/${id}`)
  console.log('delete job', res)
  await router.push({ name: 'jobs' })
}
</script>

<template>
  <useHead>
    <title>{{ job?.name }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader
        :title="job?.name"
        :breadcrumbs="[{ text: $t('nav.jobs'), to: { name: 'jobs' } }]"
        :loading="isLoading"
      >
        <template #actions>
          <Button icon-only>
            <PhDotsThreeOutline weight="fill" />

            <template #menu>
              <MenuItem :to="{ name: 'candidates-import' }">
                <PhDownloadSimple weight="regular" />
                {{
                  $t('actions.importModel', { model: $t('models.candidates') })
                }}
              </MenuItem>

              <MenuItem destructive @click="deleteJob">
                <PhTrash weight="duotone" />
                {{ $t('actions.delete') }}
              </MenuItem>
            </template>
          </Button>
        </template>
      </PageHeader>
    </template>

    <div v-if="job">
      <section class="mb-10 flex flex-col gap-10">
        <Card size="md" volume="quiet">
          <DetailList
            :entries="job"
            :fields="['companyName', 'salary', 'createdAt', 'importedAt']"
            i18n-prefix="fields.job"
          >
            <template #companyName-icon>
              <PhBuildingOffice weight="duotone" />
            </template>

            <template #salary-icon>
              <PhMoneyWavy weight="duotone" />
            </template>

            <template #createdAt-icon>
              <PhCirclesThreePlus weight="duotone" />
            </template>

            <template #importedAt-icon>
              <PhTrayArrowDown weight="duotone" />
            </template>

            <template #createdAt-value="{ value }">
              <DateTimeFormat v-if="value" :date="value" />
            </template>

            <template #importedAt-value="{ value }">
              <DateTimeFormat v-if="value" :date="value" />
            </template>
          </DetailList>
        </Card>

        <DForm
          id="job-form"
          v-slot="{ fields }"
          :schema="jobFormSchema"
          :initial-values="job"
          :loading="isLoading"
          submit-text=""
          cancel-text=""
          class="grid gap-x-10 gap-y-5 xl:grid-cols-2"
          @submit="save"
        >
          <DGroup :fields="fields.slice(0, 2)" class="space-y-5" />
          <DGroup :fields="fields.slice(2)" class="space-y-5" />
        </DForm>
      </section>

      <section>
        <header class="mb-5 flex items-center justify-between gap-5">
          <h2 class="text-base font-semibold">
            {{ $t('pages.jobsEdit.candidatesTitle') }}
          </h2>

          <div class="text-muted">
            {{ candidates.length }} / {{ totalCandidates }}
          </div>
        </header>

        <Empty
          v-if="candidates.length === 0 && isLoadingCandidates"
          :title="$t('messages.loading')"
          size="lg"
        >
          <Loading />
        </Empty>

        <Empty
          v-if="candidates.length === 0 && !isLoadingCandidates"
          :title="$t('messages.noModel', { model: $t('models.candidates') })"
          size="lg"
          muted
        >
          <template #actions>
            <Button>
              <PhDownloadSimple weight="regular" />
              {{
                $t('actions.importModel', { model: $t('models.candidates') })
              }}
            </Button>
          </template>
        </Empty>

        <ul v-if="candidates.length > 0" class="mb-5 space-y-2">
          <Card
            v-for="candidate in candidates"
            :key="candidate.id"
            as="li"
            padding="lg"
            header-class="flex items-center gap-5"
          >
            <template #header>
              <router-link
                :to="{
                  name: 'candidates-edit',
                  params: { id: job.id, candidateId: candidate.id },
                }"
                class="mr-auto font-semibold"
              >
                {{ candidate.name }}
              </router-link>

              <Button
                :to="{
                  name: 'candidates-edit',
                  params: { id: job.id, candidateId: candidate.id },
                }"
                size="sm"
              >
                <PhPencilSimpleLine weight="duotone" />
                {{ $t('actions.edit') }}
              </Button>
            </template>

            <template #default>
              <DetailList
                :entries="candidate"
                :fields="['email', 'phone', 'createdAt', 'status']"
                i18n-prefix="fields.candidate"
              >
                <template #createdAt-value="{ value }">
                  <DateTimeFormat v-if="value" :date="value" />
                </template>
              </DetailList>
            </template>
          </Card>
        </ul>

        <footer class="flex items-start justify-between gap-5">
          <Button
            v-if="candidates.length < totalCandidates"
            :disabled="candidates.length >= totalCandidates"
            :loading="isLoadingCandidates"
            @click="fetchCandidates"
          >
            {{ $t('actions.loadMore') }}
          </Button>

          <div class="text-muted">
            {{ candidates.length }} / {{ totalCandidates }}
          </div>
        </footer>
      </section>
    </div>

    <template #sticky-footer>
      <Button volume="loud" form="job-form" type="submit" class="w-32">
        <PhCheck weight="bold" />
        {{ $t('actions.save') }}
      </Button>
    </template>
  </PageLayout>
</template>
