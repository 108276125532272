<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { getApi, useApi } from '@/plugins/api'
import {
  Button,
  Card,
  DateTimeFormat,
  DetailList,
  Empty,
  Loading,
} from '@/components'
import { Candidate, Job } from '@@/types'
import { PhDotsThreeOutline, PhDownloadSimple } from '@phosphor-icons/vue'

const route = useRoute()
const router = useRouter()

const api = getApi()

const id = route.params.id
const { data: job, isLoading } = useApi<Job>(`/jobs/${id}`)

const { data: candidates } = useApi<Candidate[]>(
  `/jobs/${id}/candidates/import`
)

const importCandidate = async (remoteId: string | number) => {
  await api.post<Candidate>(`/jobs/${id}/candidates/import/${remoteId}`)

  router.push({ name: 'jobs-edit', params: { id } })
}

// const importAll = async () => {
//   const res = await api.post<Candidate[]>(`/jobs/${id}/candidates/import`)
//   console.log('import candidates', res)
//   await execute()
// }
</script>

<template>
  <useHead>
    <title>{{ $t('pages.candidatesImport.title') }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader
        :title="$t('pages.candidatesImport.title')"
        :breadcrumbs="[
          { text: $t('nav.jobs'), to: { name: 'jobs' } },
          { text: job?.name, to: { name: 'jobs-edit', id: job?.id } },
        ]"
        :loading="isLoading"
      >
        <template #actions>
          <Button icon-only>
            <PhDotsThreeOutline weight="fill" />
          </Button>
        </template>
      </PageHeader>
    </template>

    <Empty
      v-if="!candidates && isLoading"
      :title="$t('messages.loading')"
      size="xl"
    >
      <Loading />
    </Empty>

    <Empty
      v-else-if="!candidates && !isLoading"
      :title="$t('messages.noModel', { model: $t('models.candidates') })"
      size="xl"
      muted
    />

    <section v-else>
      <ul class="mb-5 space-y-2">
        <Card
          v-for="candidate in candidates"
          :key="candidate.id"
          as="li"
          :class="{ 'opacity-50': candidate.importedAt }"
        >
          <template #title>
            <h4>{{ candidate.name }}</h4>
          </template>

          <template #actions>
            <Button
              v-if="!candidate.importedAt"
              size="sm"
              @click="importCandidate(candidate.id)"
            >
              <PhDownloadSimple weight="regular" />
              {{ $t('actions.import') }}
            </Button>
          </template>

          <DetailList
            :entries="candidate"
            :fields="['name', 'salary', 'createdAt', 'importedAt']"
            i18n-prefix="fields.candidate"
          >
            <template #createdAt-value="{ value }">
              <DateTimeFormat v-if="value" :date="value" />
            </template>

            <template #importedAt-value="{ value }">
              <DateTimeFormat v-if="value" :date="value" />
            </template>
          </DetailList>
        </Card>
      </ul>
    </section>
  </PageLayout>
</template>
